import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
// import _ from 'lodash';
import './style.scss';
import LandingPage from '../pages/Landing';

const IndexPage = () => {
  useEffect(() => {
    // console.log(`contentful space id: ${process.env.CONTENTFUL_SPACE_ID}`);
    // console.log(`contentful accesstoken: ${process.env.CONTENTFUL_ACCESS_TOKEN}`)
  }, []);
  return (
    <>
      <Helmet>
        <title>Auto Savvy HK</title>
      </Helmet>
      <LandingPage />
    </>
  );
};

export default IndexPage;
